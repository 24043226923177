<template>
  <div>
    <b-modal
      id="modal-add-freelancer"
      ref="modalAddfreelancer"
      hide-footer
      centered
      size="lg"
      title="Freelancer toevoegen"
      :no-close-on-backdrop="false"
    >
      <b-card-text>
        <template #title>
          <feather-icon icon="UserPlusIcon"/>
          <span>Freelancer Toevoegen</span>
        </template>

        <b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
          >
            <b-form
              @submit.prevent="handleSubmit(createFreelancer)"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Voornaam"
                    rules="required"
                  >
                    <b-form-group
                      label="Voornaam"
                      label-for="firstName"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="freelancerData.firstName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="middleName"
                    rules=""
                  >
                    <b-form-group
                      label="Tussenvoegsel"
                      label-for="middleName"
                    >
                      <b-form-input
                        id="middleName"
                        v-model="freelancerData.middleName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="5"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Achternaam"
                    rules="required"
                  >
                    <b-form-group
                      label="Achternaam"
                      label-for="lastName"
                    >
                      <b-form-input
                        id="lastName"
                        v-model="freelancerData.lastName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="E-mailadres"
                    rules="required|email"
                  >
                    <b-form-group
                      label="E-mailadres"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="freelancerData.email"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Telefoon"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Telefoon"
                      label-for="phone"
                    >
                      <b-form-input
                        id="phone"
                        type="text"
                        v-model="freelancerData.phone"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="externalIdentifier"
                    rules=""
                  >
                    <b-form-group
                      label="Kenmerk"
                      label-for="externalIdentifier"
                    >
                      <b-form-input
                        id="externalIdentifier"
                        v-model="freelancerData.externalIdentifier"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <b-form-checkbox
                      name="companyPaysFee"
                      v-model="freelancerData.companyPaysFee"
                    >
                      Bedrijf betaald de handeling fee
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12 text-right mt-1 mb-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="showAddFreelancerSpinner"
                  >
                    <b-spinner
                      small
                      class="mr-1"
                      v-if="showAddFreelancerSpinner"
                    />
                    <span>Toevoegen</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-text>
    </b-modal>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
          <b-card
            no-body
          >
            <b-card-header>
              <b-card-title class="w-100">Freelancers</b-card-title>
              <b-card-text class="font-small-2 mr-25 mb-0 w-100">
                Een overzicht van uw freelancer relatie's
              </b-card-text>
            </b-card-header>
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <label>Toon</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label>items</label>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      placeholder="Zoeken op naam of email..."
                    />
                    <b-button
                      variant="primary"
                      @click="openModalCreateFreelancer"
                    >
                      <span class="text-nowrap">Toevoegen</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-table
              ref="refFreelancersListTable"
              class="position-relative"
              :items="fetchFreelancers"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <template #cell(contactperson)="data">
                {{ getFullName(data.item) }}
              </template>

              <template #cell(openAmount)="data">
                &euro; {{ data.value?parseFloat(data.value).toFixed(2):'0.00' }}
              </template>

              <template #cell(hasSocialCharges)="data">
                <b-badge
                  :variant="data.value === 1?'success':'danger'"
                  class="badge-pill mr-1 ml-auto"
                  style="padding-left:9px;padding-right:9px;"
                >
                  {{ data.value === 1?'Ja':'Nee' }}
                </b-badge>
              </template>

              <template #cell(status)="data">
                <b-badge
                  :variant="getStatus(data.item).variant"
                  class="badge-pill mr-1 ml-auto"
                  style="padding-left:9px;padding-right:9px;"
                >
                  {{ getStatus(data.item).value }}
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-link
                  :to="{ name: 'app-freelancer-edit', params: { id: data.item.id }}"
                  :id="`freelancer-edit-action-row-${data.item.id}`"
                >
                  <feather-icon
                    :id="`freelancer-row-${data.item.id}-edit-icon`"
                    class="cursor-pointer mx-1"
                    icon="EyeIcon"
                    size="16"
                  />
                </b-link>
                <b-tooltip
                  :target="`freelancer-edit-action-row-${data.item.id}`"
                  placement="left"
                >
                  <p class="mb-0">
                    Aanpassen
                  </p>
                </b-tooltip>
                <feather-icon
                  v-if="data.item.status !== 10"
                  :id="`freelancer-row-${data.item.id}-mail-icon`"
                  class="cursor-pointer mx-1"
                  icon="MailIcon"
                  size="16"
                  style="color: #29625b;"
                  @click="sendInvite(data.item.id)"
                />
                <b-tooltip
                  :target="`freelancer-row-${data.item.id}-mail-icon`"
                  placement="left"
                >
                  <p class="mb-0">
                    Verstuur uitnodiging
                  </p>
                </b-tooltip>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  BFormCheckbox,
  VBToggle, BSpinner, BBadge, BTooltip,
} from 'bootstrap-vue'
import { getFullName } from '@core/utils/utils'
import store from '@/store'
import { required } from '@validations'
import { onUnmounted } from '@vue/composition-api'
import useFreelancersList from '@/views/pages/freelancers/useFreelancersList'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import freelancersStoreModule from '@/views/pages/freelancers/freelancersStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardText,
    BButton,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BLink,
    BFormCheckbox,
    BSpinner,
    BBadge,
    BTooltip,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  data() {
    return {
      freelancerData: {},
      showAddFreelancerSpinner: false,
    }
  },
  methods: {
    getStatus(item) {
      if (item.status !== 10) {
        return { value: 'Wacht op activatie', variant: 'danger' }
      }

      if (item.isVerified === 0) {
        return { value: 'Ongeverifieerd', variant: 'warning' }
      }

      return { value: 'Geverifieerd', variant: 'success' }
    },
    openModalCreateFreelancer() {
      this.$refs.modalAddfreelancer.show()
    },
    createFreelancer() {
      this.showAddFreelancerSpinner = true
      store.dispatch('app-freelancer/addFreelancer', this.freelancerData)
        .then(() => {
          this.showAddFreelancerSpinner = false
          this.$refs.modalAddfreelancer.hide()
          this.refetchData()
        })
        .catch(() => {
          this.showAddFreelancerSpinner = false
          this.$refs.modalAddfreelancer.hide()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Toevoegen freelancer',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
            },
          })
        })
    },
    sendInvite(id) {
      store.dispatch('app-freelancer/sendInviteFreelancer', id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Freelancer uitnodiging',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'De uitnodiging is met succes verzonden.',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Freelancer uitnodiging',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het verzenden van de uitnodiging. Probeer later het nog een keer.',
            },
          })
        })
    },
  },
  setup() {
    const FREELANCERS_APP_STORE_MODULE_NAME = 'app-freelancer'

    // Register module
    if (!store.hasModule(FREELANCERS_APP_STORE_MODULE_NAME)) store.registerModule(FREELANCERS_APP_STORE_MODULE_NAME, freelancersStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FREELANCERS_APP_STORE_MODULE_NAME)) store.unregisterModule(FREELANCERS_APP_STORE_MODULE_NAME)
    })

    const {
      getValidationState,
    } = formValidation()

    const {
      tableColumns,
      perPage,
      total,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      showOverlay,
      searchQuery,
      dataMeta,
      refFreelancersListTable,
      currentPage,
      fetchFreelancers,
      refetchData,
    } = useFreelancersList()

    return {
      tableColumns,
      perPage,
      total,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      showOverlay,
      searchQuery,
      dataMeta,
      refFreelancersListTable,
      currentPage,
      fetchFreelancers,
      refetchData,
      getValidationState,
      getFullName,
      required,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
